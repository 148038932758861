//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StepLayout from './step-layout.vue';
import IconBrain from '../components/icon-brain.vue';
import ellipseInvoice from '../resources/ellipse-invoice.png';

export default {
  components: {
    StepLayout,
    IconBrain
  },
  data: () => ({
    ellipseInvoice,
    progress: 1,
    delayMultiplier: 1
  }),
  mounted() {
    this.loop();
  },
  methods: {
    // Funcion que modifica el progreso falso
    loop() {
      setTimeout(() => {
        this.progress += 1;

        if (this.progress % 25 === 0) {
          this.delayMultiplier += 1;
        }

        if (this.progress < 90) {
          this.loop();
        }
      }, 25 * this.delayMultiplier);
    },
    // Funcion que modifica el progreso falso cuando hay que finalizar el progreso
    finishLoop() {
      window.requestAnimationFrame(() => {
        this.progress += 1;

        if (this.progress < 100) {
          this.finishLoop();
        }
      });
    }
  }
};
