//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    percent: Number,
    bar: Boolean
  },
  computed: {
    svgContainerStyle() {
      if (!this.bar) return {};

      const HEIGHT = 97;
      const percentHeight = Math.round((HEIGHT / 100) * this.percent);
      const remainingHeight = HEIGHT - percentHeight;

      return {
        height: `${percentHeight}px`,
        marginTop: `${remainingHeight}px`
      };
    }
  }
};
